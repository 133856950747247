<template>
  <mega-card class="card-border card-round rounded">
    <div class="media p-relative">
      <div class="card-media card-video">
        <mega-image ratio="3x4" :src="data.stream_object.preview" />

        <div class="media-overlay">
          <div class="d-flex justify-content-between">
            <span
              v-if="data.live"
              class="btn btn-danger btn-sm rounded ml-2 mb-auto mt-2 p-0 cursor-default"
              style="width: 25%; pointer-events: none"
            >
              {{ $t("stream_live") }}
            </span>

            <span class="transp-back p-1 m-1" v-if="timeGoing">
              {{ getTimeDif() }}
            </span>
          </div>
          <div class="d-flex mt-auto justify-content-between transp-back">
            <span
              class="btn grey"
              style="font-size: 10px; text-overflow: initial; pointer-events: none"
              ><i class="i-eye" style="font-size: 14px"></i>
              {{ data.viewer_count }}</span
            >
            <div class="d-flex ml-auto mt-auto">
              <span
                class="btn grey"
                style="font-size: 10px; text-overflow: initial; pointer-events: none"
                ><i class="i-heart" style="font-size: 14px"></i>
                {{ data.like_count }}</span
              >
              <span
                class="btn grey"
                style="font-size: 10px; text-overflow: initial; pointer-events: none"
                ><i style="font-size: 14px" class="i-message-circle"></i>
                {{ data.comment_count }}</span
              >
            </div>
          </div>
        </div>

        <!-- <div class="media-overlay overlay-50 overlay-dark">
          <div class="text-center">
            <span class="sticker sticker-lg bg-white"
              ><i class="i-play"></i
            ></span>
          </div>
        </div> -->

        <!-- <div
          class="media-overlay overlay-90 overlay-dark media-video"
          @mouseenter="play"
          @mouseleave="pause"
        >
          <router-link
            class="p-absolute fill-parent z-3"
            :to="'/round/' + id"
          ></router-link>

          <video
            ref="video"
            class="video-player fill-parent h-100"
            style="object-fit: cover"
            :src="video_link"
            loop
          ></video>
        </div> -->
      </div>
    </div>

    <div class="navbar py-3 stream-container">
      <div class="container-fluid">
        <router-link
          :to="{ name: 'User', params: { id: data.stream_object.idt_user } }"
          class="navbar-label w-100 text-dark"
        >
          <mega-image
            class="rounded-full navbar-avatar mr-3"
            ratio="1x1"
            small
            :src="data.stream_object.avatar"
            style="overflow:hidden;"
          />

          <div class="content d-flex" style="width: calc(100% - 78px);">
            <small class="strong text-overflow">
              <span>{{ data.stream_object.name || $t("no_name") }} </span>
            </small>
            <div class="d-flex align-items-center">
              <small class="text-muted text-overflow"
                >@{{ data.stream_object.username }}
              </small>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </mega-card>
</template>

<script>
export default {
  name: "StreamCard",
  props: {
    data: Object
  },
  data() {
    return {
      timeGoing: "",
      // live: this.data.live,
      interval: ""
    };
  },
  computed: {
    live() {
      return this.data.live;
    }
    // timeGoing() {
    //   let date = new Date(this.data.stream_object.created_at);
    //   let dif = new Date(new Date() - date)
    //   return dif
    // },
    // timeDif() {
    //   let hours = this.timeGoing.getHours() - 3
    //   let minutes = this.timeGoing.getMinutes()
    //   let seconds = this.timeGoing.getSeconds().toString().length > 1 ? this.timeGoing.getSeconds() : '0' + this.timeGoing.getSeconds()
    //   return `${hours}:${minutes}:${seconds}`
    // }
  },
  watch: {
    live() {
      clearInterval(this.interval);
      console.log("Stream ended");
    }
  },
  methods: {
    getTimeDif() {
      let hours = this.timeGoing.getHours() - 3;
      let minutes =
        this.timeGoing.getMinutes().toString().length > 1
          ? this.timeGoing.getMinutes()
          : "0" + this.timeGoing.getMinutes();
      let seconds =
        this.timeGoing.getSeconds().toString().length > 1
          ? this.timeGoing.getSeconds()
          : "0" + this.timeGoing.getSeconds();
      return `${hours}:${minutes}:${seconds}`;
    },
    getDateDif() {
      return new Date(
        new Date() - new Date(this.data.stream_object.started_at)
      );
    },
    intervalRefresh() {
      // console.log('foo');
      this.timeGoing.setSeconds(this.timeGoing.getSeconds() + 1);
      this.timeGoing = this.getDateDif();
    }
  },
  mounted() {
    const realSrc = this.data.stream_object.preview;
    this.timeGoing = this.getDateDif();
    this.interval = setInterval(this.intervalRefresh, 1000);
    setTimeout(() => {
      this.data.stream_object.preview = "about:blank";
      setTimeout(() => {
        this.data.stream_object.preview = realSrc;
      }, 0);
    }, 5000);
  }
};
</script>

<style lang="sass" scoped>
.grey
  color: #f0f1f2
.transp-back
  background-color: rgba(0, 0, 0, 0.3)
  border-radius: 0.5rem
</style>
