var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-card", { staticClass: "card-border card-round rounded" }, [
    _c("div", { staticClass: "media p-relative" }, [
      _c(
        "div",
        { staticClass: "card-media card-video" },
        [
          _c("mega-image", {
            attrs: { ratio: "3x4", src: _vm.data.stream_object.preview }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "media-overlay" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _vm.data.live
                ? _c(
                    "span",
                    {
                      staticClass:
                        "btn btn-danger btn-sm rounded ml-2 mb-auto mt-2 p-0 cursor-default",
                      staticStyle: { width: "25%", "pointer-events": "none" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stream_live")) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.timeGoing
                ? _c("span", { staticClass: "transp-back p-1 m-1" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getTimeDif()) +
                        "\n          "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex mt-auto justify-content-between transp-back"
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "btn grey",
                    staticStyle: {
                      "font-size": "10px",
                      "text-overflow": "initial",
                      "pointer-events": "none"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "i-eye",
                      staticStyle: { "font-size": "14px" }
                    }),
                    _vm._v("\n            " + _vm._s(_vm.data.viewer_count))
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex ml-auto mt-auto" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn grey",
                      staticStyle: {
                        "font-size": "10px",
                        "text-overflow": "initial",
                        "pointer-events": "none"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "i-heart",
                        staticStyle: { "font-size": "14px" }
                      }),
                      _vm._v("\n              " + _vm._s(_vm.data.like_count))
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "btn grey",
                      staticStyle: {
                        "font-size": "10px",
                        "text-overflow": "initial",
                        "pointer-events": "none"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "i-message-circle",
                        staticStyle: { "font-size": "14px" }
                      }),
                      _vm._v(
                        "\n              " + _vm._s(_vm.data.comment_count)
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "navbar py-3 stream-container" }, [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-label w-100 text-dark",
              attrs: {
                to: {
                  name: "User",
                  params: { id: _vm.data.stream_object.idt_user }
                }
              }
            },
            [
              _c("mega-image", {
                staticClass: "rounded-full navbar-avatar mr-3",
                staticStyle: { overflow: "hidden" },
                attrs: {
                  ratio: "1x1",
                  small: "",
                  src: _vm.data.stream_object.avatar
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "content d-flex",
                  staticStyle: { width: "calc(100% - 78px)" }
                },
                [
                  _c("small", { staticClass: "strong text-overflow" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.data.stream_object.name || _vm.$t("no_name")
                        ) + " "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("small", { staticClass: "text-muted text-overflow" }, [
                      _vm._v(
                        "@" +
                          _vm._s(_vm.data.stream_object.username) +
                          "\n            "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }